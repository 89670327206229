import { BrandLogo } from '@ab-core/brand-logo';
import type { Colors } from '@ab-core/colors';
import type { IconProps } from '@ab-core/icon';
import { Link, LINK_VARIANT } from '@ab-core/link';
import { getTestIdProp } from '@ab-core/testing';
import type { FC } from 'react';
import React from 'react';
import {
    FooterContainer,
    IconFooter,
    LeftFooter,
    LinksFooter,
    LogoButton,
    LogoSizer,
    SocialMediaFooter,
    StyledLinkText
} from './styled';

type SocialMediaType = {
    iconname: IconProps['name'];
    link: string;
};

type LinksType = {
    name: string;
    link: string;
};

export type FooterProps = {
    homelink?: string;
    socialmediaColor?: keyof typeof Colors;
    socialmedia?: Array<SocialMediaType>;
    links?: Array<LinksType>;
};

const Footer: FC<FooterProps> = (props) => {
    const { homelink, socialmediaColor, socialmedia, links } = props;

    return (
        <FooterContainer>
            <LeftFooter>
                <Link to={homelink || './'} aria-label="Alexander Buerkle Logo">
                    <LogoButton>
                        <LogoSizer>
                            <BrandLogo />
                        </LogoSizer>
                    </LogoButton>
                </Link>
                <SocialMediaFooter className="socialmedia">
                    {socialmedia?.map((sm, i) => (
                        <span key={i}>
                            <Link
                                variant={LINK_VARIANT.LINK_ONLY}
                                to={sm.link}
                                {...getTestIdProp(`footer-go-to-social-media-${sm.iconname}`)}
                                aria-label={sm.iconname}
                            >
                                <IconFooter name={sm.iconname} color={socialmediaColor} />
                            </Link>
                        </span>
                    ))}
                </SocialMediaFooter>
            </LeftFooter>
            <LinksFooter>
                {links?.map((footerlink, i) => (
                    <Link
                        variant={LINK_VARIANT.LINK_ONLY}
                        to={footerlink.link}
                        key={i}
                        {...getTestIdProp(`footer-go-to-link-${footerlink.name}`)}
                    >
                        <StyledLinkText>{footerlink.name}</StyledLinkText>
                    </Link>
                ))}
            </LinksFooter>
        </FooterContainer>
    );
};

export default Footer;
